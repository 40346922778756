import React from "react"
import { graphql, Link } from "gatsby"
// import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      selected: "about"
    }

    this.changeSelected = this.changeSelected.bind(this)
  }

  changeSelected(e){
    e.preventDefault();
    this.setState({ selected: e.target.name })
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    let content = ''
    switch (this.state.selected) {
      case 'about':
        content = <div>
          <p>I am a pragmatic developer, with previous experience on the commercial side of venture-backed businesses. I've worked across scaling organisations such as <OutboundLink href="https://deliveroo.co.uk/">Deliveroo</OutboundLink>, <OutboundLink href="https://digitalfineprint.com/">Digital Fineprint</OutboundLink> and <OutboundLink href="https://checkout.timeout.com/">YPlan</OutboundLink> (acq. by TimeOut).</p>
          <p>In my own time, I build and launch <Link to="/projects/">side-projects</Link>. I love to create and nurture products and communities. I view technology as a tool and embrace tool-building.</p>
          <p>I have a passion for financial markets and investing. I like to understand how businesses grow and evolve over time and the impact that can make for individuals.</p>
          <p>I have a curious mind, which continually leads me to new learning experiences. I read broadly when I can and follow my curiosities often.</p>
          <p>I beleive in small, deliberate improvements over long periods of time, enabled by patience and optimism.</p>
        </div>
        break;
      case 'startups':
        content = <div>
          <p>I've worked in startups for over 7 years, covering both the tech and commercial side in both consumer and B2B ventures.</p>
          <p>My current focus is on building pragmatic and purposefully-designed products. At <OutboundLink href="https://digitalfineprint.com/">Digital Fineprint</OutboundLink>, I built Saas software for large insurers. As an engineer, I seek to always be the voice of the customer on my team. I guide by development decisions on the potential outcome for the customer, whilst ensuring the code remains easy to work on in the future. </p>
          <p>Previously, I worked on the commercial side of startups. At <OutboundLink href="https://deliveroo.co.uk/">Deliveroo</OutboundLink>, I launched new cities across Scotland and Northern England, before building national and international partnerships from our London HQ. At <OutboundLink href="https://checkout.timeout.com/">YPlan</OutboundLink> (acq. by TimeOut), I developed relationships for the supply-side of the business.</p>
          <p>My experience across scaling organisations has sharpened my focus on teams and retaining their productivity as they grow.</p>
          <ul>
            <p>Some areas I find interesting:</p>
            <li>Marketplaces</li>
            <li>API businesses</li>
            <li>Personal Finance 2.0</li>
            <li>Alternative Education</li>
            <li>B2b and Niche Saas</li>
          </ul>
        </div>
        break;
      case 'indiehacker':
        content = <div>
          <p>Having worked in venture-backed startups, I understand the pressure that a need for continual growth can put on a business and its team.</p>
          <p>The <OutboundLink href="https://www.indiehackers.com/">IndieHacker</OutboundLink> community provides a balance to this. I enjoy the communities focus on creation, self-reliance and speed to profitability.</p>
          <p>In my own time, I create side-projects from design all the way through to launch. Sometimes the goal is simply to build and ship, learning new techniques along the way. Other times I attempt to monetize products. Currently, I am building <OutboundLink href="https://www.dreamweeks.co/">showmethemoney.com</OutboundLink>, <OutboundLink href="https://www.dreamweeks.co/">dreamweeks.co</OutboundLink> and <OutboundLink href="https://www.codewithbootcamps.com/">codewithbootcamps.com</OutboundLink>.</p>
          <p>The ability to build MVPs and launch my own products was a big motivator behind learning to code, so I try to flex this muscle as often as I can.</p>
        </div>
        break;
      case 'investing':
        content = <div>
          <p>From a young age, I was interested in the formation of businesses and their growth. As I began to work in tech startups, I realised I was in a great position to spot trends early and see new business models. I began to explore the stock market in order to act on some of these insights.</p>
          <p>Over the following years, I have dug deeper into both business and financial markets. It is a passion of mine, walking the intersection between technology, business and human psychology.</p>
          <p>My current investing strategy is to hold 50% in low risk index funds and 50% in individual stocks I select myself. For individual stocks, I focus on quality (e.g. Amazon, Adobe) or newer growth stocks (e.g. Sea Ltd, StoneCo).</p>
          <p>At the request of some close friends, I wrote up a small guide on <a href="../personal-finance/">personal finance and investing</a>.</p>
        </div>
        break;
      case 'stats':
        content = <div className="about-stats">
          <ul>
            <li><strong>Education</strong>: BA Hons. Economics &amp; Chinese Studies, University of Nottingham</li>
            <li><strong>Heritage</strong>: Serbian &amp; Portuguese</li>
            <li><strong>Myers-Briggs</strong>: <OutboundLink href="https://www.16personalities.com/entj-personality">ENTJ-A</OutboundLink></li>
            <li><strong>Favourite Sport</strong>: Football</li>
            <li><strong>Favourite Novel</strong>: The Kite Runner</li>
            <li><strong>Nickname</strong>: Popo</li>
            <li><strong>Unusual Talent</strong>: Very flexible for a tall man</li>
          </ul>
        </div>
        break;
      default:
        console.log('We could not find the right content');
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO 
          title="About Me"
          description="A bit more about me, if you happen to be interested."
        />
        <nav className="about-nav">
          <button className={this.state.selected === "about"? "about-button selected" : "about-button"} name="about" onClick={this.changeSelected}>About Me</button>
          <button className={this.state.selected === "startups"? "about-button selected" : "about-button"} name="startups" onClick={this.changeSelected}>Startups</button>
          <button className={this.state.selected === "indiehacker"? "about-button selected" : "about-button"} name="indiehacker" onClick={this.changeSelected}>IndieHacker</button>
          <button className={this.state.selected === "investing"? "about-button selected" : "about-button"} name="investing" onClick={this.changeSelected}>Investing</button>
          <button className={this.state.selected === "stats"? "about-button selected" : "about-button"} name="stats" onClick={this.changeSelected}>Stats</button>
        </nav>

        {content}
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`